<template>
  <div class="content-wrapper overflow-visible">
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.query.regId" />
    <div class="content">
      <validation-observer ref="VForm">
        <b-form @submit.prevent="doSubmit()">
          <div class="card">
            <div class="card-header bg-white">
              <div class="row align-items-center">
                <div class="col-md-auto">
                  <div class="row align-items-center">
                    <div class="col-md-auto">
                      <h5 class="card-title font-weight-semibold">Laporan Anestesi</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="">Tanggal<strong class="text-danger">*</strong></label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-calendar22"></i></span>
                    </div>
                    <datepicker :clear-button="true" input-class="form-control transparent" 
                        calendar-class="my-datepicker"
                        v-model="row.aranla_date">
                    </datepicker>
                  </div>
                  <VValidate 
                    name="Tanggal" 
                    v-model="row.aranla_date" 
                    :rules="toValidate(mrValidation.aranla_date)"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="">Kegawatan Operasi<strong class="text-danger">*</strong></label>
                  <b-form-radio-group
                    :options="Config.mr.ranapJenisOperasi"
                    v-model="row.aranla_jenis_operasi"
                  />

                  <VValidate 
                    name="Kegawatan Operasi" 
                    v-model="row.aranla_jenis_operasi" 
                    :rules="toValidate(mrValidation.aranla_jenis_operasi)"
                  />
                </div>
              </div>
              <div class="w-100"></div>
              <div class="col-md-9">
                <div class="form-group">
                  <table class="table table-bordered table-sm">
                    <thead>
                      <tr>
                        <th>Tindakan</th>
                        <th>Jam Mulai</th>
                        <th>Jam Selesai</th>
                        <th>Lama</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="table-secondary"><span class="font-weight-semibold">Anestesi</span></td>
                        <td>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text"><i class="icon-calendar22"></i></span>
                            </div>
                            <datepicker @input="autofill($event,'aranla_pemantauan_fisiologis_tanggal')" :clear-button="true" input-class="form-control transparent" 
                                calendar-class="my-datepicker"
                                v-model="row.aranla_anastesi_date_start">
                            </datepicker>
                          </div>

                          <div class="input-group input-group-sm mt-2">
                            <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                            <vue-timepicker @input="autofill($event,'aranla_pemantauan_fisiologis_jam')" manual-input format="HH:mm" input-class="form-control" v-model="row.aranla_anastesi_jam_start">
                            </vue-timepicker>
                          </div>
                          
                          <VValidate 
                            name="Tanggal Mulai Anestesi" 
                            v-model="row.aranla_anastesi_date_start" 
                            :rules="toValidate(mrValidation.aranla_anastesi_date_start)"
                          />
                          <VValidate 
                            name="Jam Mulai Anestesi" 
                            v-model="row.aranla_anastesi_jam_start" 
                            :rules="toValidate(mrValidation.aranla_anastesi_jam_start)"
                          />
                        </td>
                        <td>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text"><i class="icon-calendar22"></i></span>
                            </div>
                            <datepicker :clear-button="true" input-class="form-control transparent" 
                                calendar-class="my-datepicker"
                                v-model="row.aranla_anastesi_date_end">
                            </datepicker>
                          </div>

                          <div class="input-group input-group-sm mt-2">
                            <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                            <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.aranla_anastesi_jam_end">
                            </vue-timepicker>
                          </div>
                          <VValidate 
                            name="Tanggal Berakhir Anestesi" 
                            v-model="row.aranla_anastesi_date_end" 
                            :rules="toValidate(mrValidation.aranla_anastesi_date_end)"
                          />
                          <VValidate 
                              name="Jam Berakhir Anestesi" 
                              v-model="row.aranla_anastesi_jam_end" 
                              :rules="toValidate(mrValidation.aranla_anastesi_jam_end)"
                            />
                        </td>
                        <td>{{distanceAnestesi||'-'}}</td>
                      </tr>
                      <tr>
                        <td class="table-secondary"><span class="font-weight-semibold">Operasi</span></td> 
                        <td>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text"><i class="icon-calendar22"></i></span>
                            </div>
                            <datepicker :clear-button="true" input-class="form-control transparent" 
                                calendar-class="my-datepicker"
                                v-model="row.aranla_operasi_date_start">
                            </datepicker>
                          </div>

                          <div class="input-group input-group-sm mt-2">
                            <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                            <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.aranla_operasi_jam_start">
                            </vue-timepicker>
                          </div>
                          
                          <VValidate 
                            name="Tanggal Mulai Operasi" 
                            v-model="row.aranla_operasi_date_start" 
                            :rules="toValidate(mrValidation.aranla_operasi_date_start)"
                          />
                          <VValidate 
                            name="Jam Mulai Operasi" 
                            v-model="row.aranla_operasi_jam_start" 
                            :rules="toValidate(mrValidation.aranla_operasi_jam_start)"
                          />
                        </td>
                        <td>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text"><i class="icon-calendar22"></i></span>
                            </div>
                            <datepicker :clear-button="true" input-class="form-control transparent" 
                                calendar-class="my-datepicker"
                                v-model="row.aranla_operasi_date_end">
                            </datepicker>
                          </div>

                          <div class="input-group input-group-sm mt-2">
                            <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                            <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.aranla_operasi_jam_end">
                            </vue-timepicker>
                          </div>
                          <VValidate 
                            name="Tanggal Berakhir Operasi" 
                            v-model="row.aranla_operasi_date_end" 
                            :rules="toValidate(mrValidation.aranla_operasi_date_end)"
                          />
                          <VValidate 
                              name="Jam Berakhir Operasi" 
                              v-model="row.aranla_operasi_jam_end" 
                              :rules="toValidate(mrValidation.aranla_operasi_jam_end)"
                            />
                        </td>
                        <td>{{distanceOperasi||'-'}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <table class="table table-bordered table-sm">
                    <thead>
                      <tr>
                        <th>Tindakan Operasi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><span class="text-uppercase">{{row.arano_tindakan_operasi||"-"}}</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="w-100"></div>
              <div class="col-md-4">
                <div>
                  <label for="">Dokter Operator</label>
                  <p>{{row.dokter_operator||"-"}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6" v-if="row.arano_id">
                <ICD10Operasi title="Diagnosa Pra-Operasi" type="LaporanAnestesiPraOperasi" :idOp="row.arano_id" />
                <div class="form-group p-3 custom-diagnosa">
                  <label for="diagPraOperasi">Diagnosa Pra-Operasi Lainnya <strong  v-if="isReqLaporanAnestesiPraOperasi" class="text-danger">*</strong></label>
                  <b-form-textarea v-model="row.aranla_diagnosa_pra_operasi" name="diagPraOperasi" id="diagPraOperasi" rows="4" class="form-control"></b-form-textarea>
                  
                  <VValidate 
                    v-if="isReqLaporanAnestesiPraOperasi"
                    name="Diagnosa Pra-Operasi" 
                    v-model="row.aranla_diagnosa_pra_operasi" 
                    :rules="toValidate(mrValidation.aranla_diagnosa_pra_operasi)"
                  />
                </div>
              </div>
              <div class="col-md-6" v-if="row.arano_id">
                <ICD10Operasi title="Diagnosa Post-Operasi" type="LaporanAnestesiPostOperasi" :idOp="row.arano_id" />
                <div class="form-group p-3 custom-diagnosa">
                  <label for="diagPostOperasi">Diagnosa Post-Operasi <strong v-if="isReqLaporanAnestesiPostOperasi" class="text-danger">*</strong></label>
                  <b-form-textarea v-model="row.aranla_diagnosa_post_operasi" name="diagPostOperasi" id="diagPostOperasi" rows="4" class="form-control"></b-form-textarea>
                  
                  <VValidate 
                    v-if="isReqLaporanAnestesiPostOperasi"
                    name="Diagnosa Post-Operasi" 
                    v-model="row.aranla_diagnosa_post_operasi" 
                    :rules="toValidate(mrValidation.aranla_diagnosa_post_operasi)"
                  />
                </div>
              </div>
            </div>
            <div class="wrap_line">
              <h3>Pemeriksaan Pra-Tindakan</h3>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Tekanan Darah</label>
                    <div class="input-group">
                      <b-form-input :formatter="number"
                      v-model="row.aranla_tekanan_darah_min" type="text" name="name"
                      class="form-control" placeholder="Systole" />
                      <div class="input-group-append input-group-prepend">
                      <span class="input-group-text">/</span>
                      </div>
                      <b-form-input :formatter="number" placeholder="Diastole"
                      v-model="row.aranla_tekanan_darah_max" type="text"
                      class="form-control" />
                      <div class="input-group-append"><span class="input-group-text">mmHG</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label>Nadi</label>
                    <div class="input-group">
                      <b-form-input :formatter="number"
                      v-model="row.aranla_nadi" type="text" name="name"
                      class="form-control" placeholder="Nadi" />
                      <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                      <div class="input-group-append">
                        <div style="width: 150px;">
                          <v-select placeholder="Pilih Label" v-model="row.aranla_label"
                          :options="Config.mr.StatusRegular" label="text"
                          :clearable="true" :reduce="v=>v.value"></v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--
                <div class="col-md-2">
                  <div class="form-group">
                    <label>Hb</label>
                    <div class="form-row">
                      <div class="col-md-12">
                        <div class="input-group">
                          <b-form-input :formatter="number" placeholder="Hb"
                          v-model="row.aranla_hb" type="text"
                          class="form-control" />
                          <div class="input-group-append"><span class="input-group-text">mg/dL</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                -->
                <div class="col-md-2">
                  <div class="form-group">
                    <label>Pernafasan</label>
                    <div class="input-group">
                      <b-form-input :formatter="number" placeholder="Pernafasan"
                          v-model="row.aranla_pernafasan" type="text"
                        class="form-control" />
                      <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label>Suhu</label>
                    <div class="form-row">
                      <div class="col-md-12">
                        <div class="input-group">
                          <b-form-input :formatter="number" placeholder="Suhu"
                            v-model="row.aranla_suhu" type="text"
                          class="form-control" />
                          <div class="input-group-append"><span class="input-group-text">&deg;C</span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Gol. Darah</label>
                     <b-form-input placeholder="Gol. Darah"
                        v-model="row.aranla_gol_darah" type="text"
                      class="form-control" />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">EKG</label>
                    <b-form-input placeholder="EKG"
                      v-model="row.aranla_ekg" type="text"
                    class="form-control" />
                  </div>
                </div>
                -->
                <div class="col-md-10">
                  <div class="form-group">
                    <label for="">ASA</label>
                    <v-select placeholder="-- Pilih Salah Satu --" v-model="row.aranla_asa"
                    :options="Config.mr.mDiagnosisASA" label="text"
                    :clearable="true" :reduce="v=>v.value"></v-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="wrap_line">
              <h3>Premedikasi</h3>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="">Premedikasi</label>
                    <textarea placeholder="Premedikasi"
                      v-model="row.aranla_premedikasi"
                    class="form-control" rows="5">
                    </textarea>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="">Dilakukan di</label>
                    <b-form-input placeholder="Dilakukan di"
                      v-model="row.aranla_dilakukan" type="text"
                    class="form-control" />
                  </div>
                </div>
                <div class="w-100"></div>
                <div class="col-md-8">
                  <div class="form-group">
                    <label for="">Obat</label>
                    <table class="table table-bordered table-sm">
                      <thead>
                        <tr>
                          <th width="20">#</th>
                          <th>Nama Obat</th>
                          <th>Keterangan</th>
                          <th>Aksi</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(v,k) in (row.aranla_obat||[])" :key="k+'obat'">
                          <td>{{k+1}}</td>
                          <td>
                            <v-select style="flex: 1;" class="med-selection select-paging"
                              placeholder="Pilih Item"
                              :class="obatLen <= 5 ? 'sp-col diagnosa-section': 'sp-col'"
                              @open="countLen" @search="filterObat($event,k,'aranla_obat');countLen();"
                              v-model="row.aranla_obat[k].item_id" :options="mObatPreferensi"
                              label="text" @input="selectJumlahSatuan($event,k,'aranla_obat')" :clearable="true"
                              :reduce="v=>v.value"
                              :selectable="v=>v.value !== 'pd' && v.value !== 'ol'">
                              <template slot="selected-option">
                              <span
                                  :class="isAlergiLabel(v.nama)?'text-warning':''">{{ v.nama }}</span>
                              </template>
                              <template slot="option" slot-scope="option">
                              <span :class="isAlergiLabel(option.text)?'text-warning':''"
                                  v-if="option.preferensi"><b>{{ option.text }}</b></span>
                              <span :class="isAlergiLabel(option.text)?'text-warning':''"
                                  v-else>{{ option.text }}</span>
                              </template>
                              <template #list-footer>
                              <li class="list-paging">
                                  <div class="d-flex align-items-center justify-content-between">
                                  <b-button
                                      @click="changePageObat(v.currentPage,k,'min','aranla_obat')"
                                      size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                      <i class="icon-arrow-left22 text-white"></i></b-button>
                                  <small>Halaman {{v.currentPage}} dari
                                      {{ceilData(v.totalRows/10)}}</small>
                                  <b-button
                                      @click="changePageObat(v.currentPage,k,'plus','aranla_obat')"
                                      size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                      <i class="icon-arrow-right22 text-white"></i></b-button>
                                  </div>
                              </li>
                              </template>
                          </v-select>
                          </td>
                          <td>
                            <textarea  v-model="row.aranla_obat[k].ket" class="form-control" rows="3"></textarea>
                          </td>
                          <td>
                            <a href="javascript:;" @click="removeObat(k,'aranla_obat')"
                            class="btn btn-sm alpha-danger border-danger text-danger-800 btn-icon rounded-round"><i
                            class="icon-trash"></i></a>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                            <td class="text-center" colspan="99">
                            <a href="javascript:;" @click="openObat('aranla_obat')" class="btn alpha-blue">
                              <i class="icon-plus2 mr-1 align-middle"></i>
                              <span class="align-middle">Tambah Resep</span>
                            </a>
                            </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <div class="col-md-3">
                  <h6 class="font-weight-semibold">Oksigenasi</h6>
                  <div class="form-group">
                    <label for="oksDurasi">Durasi</label>
                    <div class="input-group">
                      <b-form-input :formatter="number"
                          v-model="row.aranla_durasi" type="text"
                          class="form-control" />
                      <div class="input-group-append"><span class="input-group-text">menit</span></div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="oksPemberian">Pemberian</label>
                    <div class="input-group">
                      <b-form-input :formatter="number"
                          v-model="row.aranla_pemberian" type="text"
                          class="form-control" />
                      <div class="input-group-append"><span class="input-group-text">L/menit</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="wrap_line">
              <h3>Teknik Anestesi</h3>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group mb-0">
                    <label for="teknikAnestesi">General Anestesi</label>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="">IV</label>
                          <div>
                            <b-form-checkbox-group
                                v-model="row.aranla_vi"
                                :options="Config.mr.mLaporanAnestesiIV"
                                class="checkbox-block"
                                name="aranla_vi"
                            ></b-form-checkbox-group>
                          </div>
                        </div>
                      
                        <div class="form-group">
                          <div>
                            <b-form-checkbox-group
                                v-model="row.aranla_general_anestesi"
                                :options="Config.mr.generalAnestesi"
                                class="checkbox-block"
                                name="aranla_general_anestesi"
                            ></b-form-checkbox-group>
                          </div>
                          <label for="">Dengan</label>
                          <div>
                            <b-form-checkbox-group
                                v-model="row.aranla_general_anestesi_dengan"
                                :options="Config.mr.generalAnestesiDengan"
                                class="checkbox-block"
                                name="aranla_general_anestesi_dengan"
                            ></b-form-checkbox-group>

                            <div class="input-group input-group-sm" v-if="(row.aranla_general_anestesi_dengan||[]).indexOf('Lainnya') != -1">
                              <b-form-input v-model="row.aranla_general_anestesi_dengan_text" type="text" class="form-control ml-2 pr-2" />
                              <VValidate 
                                :name="'General Anestesi Lainnya'" 
                                v-model="row.aranla_general_anestesi_dengan_text" 
                                :rules="{required: 1}"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="">Umum Inhalasi</label>
                        <div class="row">
                          <div class="col-md-auto">
                            <b-form-checkbox-group
                                class="checkbox-block"
                                v-model="row.aranla_umum_inhalasi"
                                :options="Config.mr.mLaporanAnestesiUmumInhalasi"
                                name="aranla_umum_inhalasi"
                            ></b-form-checkbox-group>
                          </div>
                        </div>
                      </div>
                      <div class="mt-5">                          
                        <div class="form-group">
                          <label for="">Masker dengan</label>
                          <b-form-radio-group
                            :options="Config.mr.mLaporanAnestesiMaskerDengan"
                            v-model="row.aranla_mask"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="teknikAnestesi">Regional Anestesi</label>
                    <b-form-checkbox-group
                          v-model="row.aranla_regional"
                          :options="Config.mr.mLaporanAnestesiRegional"
                          name="aranla_regional"
                    ></b-form-checkbox-group>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">Posisi Puncture</label>
                        <b-form-input
                          v-model="row.aranla_posisi" type="text" name="name"
                          class="form-control"/>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">Level</label>
                        <div class="form-row">
                          <div class="col-md">
                            <b-form-input
                            v-model="row.aranla_level" type="text" name="name"
                            class="form-control"/>
                          </div>
                          <div class="col-md-auto">
                            <b-form-radio-group
                              :options="Config.mr.mLaporanAnestesiLevel"
                              v-model="row.aranla_level_choice"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="w-100"></div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="">Obat</label>
                        <b-form-textarea v-model="row.aranla_obat_text" class="form-control" rows="2"></b-form-textarea>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="">Volume</label>
                        <div class="input-group">
                          <b-form-input
                          v-model="row.aranla_volume" :formatter="number" type="text" name="name"
                          class="form-control"/>
                          <div class="input-group-append"><span class="input-group-text">cc</span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="wrap_line">
              <h3>Infus</h3>
              <div class="row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Tangan</label>
                    <b-form-radio-group
                      :options="mKananKiri"
                      v-model="row.aranla_infus_tangan"
                    />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Kaki</label>
                    <b-form-radio-group
                      :options="mKananKiri"
                      v-model="row.aranla_infus_kaki"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="infLokasi">Lokasi</label>
                    <b-form-textarea v-model="row.aranla_lokasi" rows="3" class="form-control">
                    </b-form-textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Jarum Infus No.</label>
                    <b-form-input
                      v-model="row.aranla_infus_no" type="text" name="name"
                      class="form-control"/>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">CVC</label>
                    <b-form-radio-group
                      :options="Config.mr.yesNoOptV2"
                      v-model="row.aranla_cvc"
                    />
                  </div>
                </div>
                <div class="col-md-3" v-if="row.aranla_cvc == 'Y'">
                  <div class="form-group">
                    <label for="">Terpasang di</label>
                    <b-form-input
                      v-model="row.aranla_terpasang_di" type="text" name="name"
                      class="form-control"/>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="infKeteranganLain">Keterangan Lain</label>
                    <b-form-textarea v-model="row.aranla_keterangan_lain" name="infKeteranganLain" id="infKeteranganLain" rows="3" class="form-control"></b-form-textarea>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="infKomplikasi">Komplikasi Akut Anestesi</label>
                    <b-form-textarea v-model="row.aranla_komplikasi" name="infKeteranganLain" id="infKeteranganLain" rows="3" class="form-control"></b-form-textarea>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="infPenanganan">Penanganan</label>
                    <b-form-textarea v-model="row.aranla_penanganan" name="infKeteranganLain" id="infKeteranganLain" rows="3" class="form-control"></b-form-textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <div class="wrap_line">
                  <div class="pb-3 d-flex align-items-center justify-content-between">
                    <h3 class="pb-0">Pemantauan Status Fisiologis</h3>
                    <a href="javascript:;" @click="doOpenFisiologis()" data-toggle="modal" class="btn btn-sm btn-labeled btn-labeled-left btn-info">
                      <b><i class="icon-pencil"></i></b>
                      <span>Ubah Data</span>
                    </a>
                  </div>
                  <div class="fisiologis">
                    <div class="form-row">
                      <div class="col-md">
                        <div class="fsg-main">
                          <img :src="row.aranla_pemantauan_fisiologis_with_image ? row.aranla_pemantauan_fisiologis_with_image: assetLocal('img/pantauan-fisiologis-bg.png')" style="max-width: 100%;">
                        </div>
                        <div class="fsg-footer"></div>
                      </div>
                    </div>
                  </div>
                  <span class="mt-2 d-block" v-if="row.aranla_pemantauan_fisiologis_last_update"><b>Terakhir Diupdate : </b> {{row.aranla_pemantauan_fisiologis_last_update | moment("DD MMMM YYYY, HH:mm")}}</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="wrap_line">
                  <div class="form-group">
                    <h3 class="pb-2">Waktu Pemantauan</h3>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="icon-calendar22"></i></span>
                      </div>
                      <datepicker :clear-button="true" input-class="form-control transparent" 
                          calendar-class="my-datepicker"
                          v-model="row.aranla_pemantauan_fisiologis_tanggal">
                      </datepicker>
                    </div>

                    <div class="input-group input-group-sm mt-2">
                      <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                      <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.aranla_pemantauan_fisiologis_jam">
                      </vue-timepicker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-8">
                    <div class="form-group">
                      <label for="">Obat yang diberikan</label>
                      <table class="table table-sm table-bordered table-input">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Nama Obat</th>
                            <th>Keterangan</th>
                            <th>Aksi</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(v,k) in (row.aranla_obat_yang_diberikan||[])" :key="k+'obat'">
                            <td>{{k+1}}</td>
                            <td>
                              <v-select style="flex: 1;" class="med-selection select-paging"
                                placeholder="Pilih Item"
                                :class="obatLen <= 5 ? 'sp-col diagnosa-section': 'sp-col'"
                                @open="countLen" @search="filterObat($event,k,'aranla_obat_yang_diberikan');countLen();"
                                v-model="row.aranla_obat_yang_diberikan[k].item_id" :options="mObatPreferensi"
                                label="text" @input="selectJumlahSatuan($event,k,'aranla_obat_yang_diberikan')" :clearable="true"
                                :reduce="v=>v.value"
                                :selectable="v=>v.value !== 'pd' && v.value !== 'ol'">
                                <template slot="selected-option">
                                <span
                                    :class="isAlergiLabel(v.nama)?'text-warning':''">{{ v.nama }}</span>
                                </template>
                                <template slot="option" slot-scope="option">
                                <span :class="isAlergiLabel(option.text)?'text-warning':''"
                                    v-if="option.preferensi"><b>{{ option.text }}</b></span>
                                <span :class="isAlergiLabel(option.text)?'text-warning':''"
                                    v-else>{{ option.text }}</span>
                                </template>
                                <template #list-footer>
                                <li class="list-paging">
                                    <div class="d-flex align-items-center justify-content-between">
                                    <b-button
                                        @click="changePageObat(v.currentPage,k,'min','aranla_obat_yang_diberikan')"
                                        size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                        <i class="icon-arrow-left22 text-white"></i></b-button>
                                    <small>Halaman {{v.currentPage}} dari
                                        {{ceilData(v.totalRows/10)}}</small>
                                    <b-button
                                        @click="changePageObat(v.currentPage,k,'plus','aranla_obat_yang_diberikan')"
                                        size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                        <i class="icon-arrow-right22 text-white"></i></b-button>
                                    </div>
                                </li>
                                </template>
                            </v-select>
                            </td>
                            <td>
                              <textarea  v-model="row.aranla_obat_yang_diberikan[k].ket" class="form-control" rows="3"></textarea>
                            </td>
                            <td>
                              <a href="javascript:;" @click="removeObat(k,'aranla_obat_yang_diberikan')"
                              class="btn btn-sm alpha-danger border-danger text-danger-800 btn-icon rounded-round"><i
                              class="icon-trash"></i></a>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                              <td class="text-center" colspan="99">
                              <a href="javascript:;" @click="openObat('aranla_obat_yang_diberikan')" class="btn alpha-blue">
                                <i class="icon-plus2 mr-1 align-middle"></i>
                                <span class="align-middle">Tambah Resep</span>
                              </a>
                              </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="">Maintenance</label>
                      <div>
                        <div class="custom-control mt-1 custom-checkbox custom-control-inline p-0">
                          <b-form-checkbox value="Y" unchecked-value="N"
                          v-model="row.aranla_maintance"
                          :name="'aranla_maintance'"
                          class="form-check-input-styled" />
                          N2O
                          <div class="input-group input-group-sm" v-if="row.aranla_maintance == 'Y'">
                            <b-form-input v-model="row.aranla_maintance_text" type="text" class="form-control ml-2" />
                            <div class="input-group-append"><span class="input-group-text">lt/m</span></div>
                        
                            <VValidate 
                              :name="'N2O'" 
                              v-model="row.aranla_maintance_text" 
                              :rules="{required: 1}"
                            />
                          </div>
                        </div>
                        
                        <div class="custom-control mt-1 custom-checkbox custom-control-inline p-0">
                          <b-form-checkbox value="Y" unchecked-value="N"
                          v-model="row.aranla_maintance_o2"
                          :name="'aranla_maintance_o2'"
                          class="form-check-input-styled" />
                          O2
                          <div class="input-group input-group-sm" v-if="row.aranla_maintance_o2 == 'Y'">
                            <b-form-input v-model="row.aranla_maintance_o2_text" type="text" class="form-control ml-2" />
                            <div class="input-group-append"><span class="input-group-text">lt/m</span></div>
                        
                            <VValidate 
                              :name="'O2'" 
                              v-model="row.aranla_maintance_o2_text" 
                              :rules="{required: 1}"
                            />
                          </div>
                        </div>

                        <div class="custom-control mt-1 custom-checkbox custom-control-inline p-0">
                          <b-form-checkbox value="Y" unchecked-value="N"
                          v-model="row.aranla_sevoflurane"
                          :name="'aranla_sevoflurane'"
                          class="form-check-input-styled" />
                          Sevoflurane
                          <div class="input-group input-group-sm" v-if="row.aranla_sevoflurane == 'Y'">
                            <b-form-input v-model="row.aranla_sevoflurane_text" type="text" class="form-control ml-2" />
                            <div class="input-group-append"><span class="input-group-text">% vol</span></div>
                        
                            <VValidate 
                              :name="'Sevoflurane'" 
                              v-model="row.aranla_sevoflurane_text" 
                              :rules="{required: 1}"
                            />
                          </div>
                        </div>
                        
                        <div class="custom-control mt-1 custom-checkbox custom-control-inline p-0">
                          <b-form-checkbox value="Y" unchecked-value="N"
                          v-model="row.aranla_isoflurane"
                          :name="'aranla_isoflurane'"
                          class="form-check-input-styled" />
                          Isoflurane
                          <div class="input-group input-group-sm" v-if="row.aranla_isoflurane == 'Y'">
                            <b-form-input v-model="row.aranla_isoflurane_text" type="text" class="form-control ml-2" />
                            <div class="input-group-append"><span class="input-group-text">% vol</span></div>
                        
                            <VValidate 
                              :name="'Isoflurane'" 
                              v-model="row.aranla_isoflurane_text" 
                              :rules="{required: 1}"
                            />
                          </div>
                        </div>

                        <div class="custom-control mt-1 custom-checkbox custom-control-inline p-0">
                          <b-form-checkbox value="Y" unchecked-value="N"
                          v-model="row.aranla_lainnya"
                          :name="'aranla_lainnya'"
                          class="form-check-input-styled" />
                          Lainnya
                          <div class="input-group input-group-sm" v-if="row.aranla_lainnya == 'Y'">
                            <b-form-input v-model="row.aranla_lainnya_text" type="text" class="form-control ml-2 pr-2" />
                            <VValidate 
                              :name="'Lainnya'" 
                              v-model="row.aranla_lainnya_text" 
                              :rules="{required: 1}"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="">Posisi</label>
                      <div>
                        <b-form-radio-group
                          class="custom-inline"
                          :options="Config.mr.mLaporanAnestesiPosisi"
                          v-model="row.aranla_posisi"
                        />
                        <VValidate 
                          name="Posisi" 
                          v-model="row.aranla_posisi" 
                          :rules="toValidate(mrValidation.aranla_posisi)"
                        />
                      </div>
                    </div>
                  </div>
                  <!--
                  <div class="col-12">
                    <h6 class="font-weight-semibold">Cairan Masuk</h6>
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="">RL</label>
                          <div class="input-group">
                            <b-form-input :formatter="number"
                            v-model="row.aranla_cm_rl" type="text" name="name"
                            class="form-control" placeholder="RL" />
                            <div class="input-group-append"><span class="input-group-text">cc</span></div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="">NaCl</label>
                          <div class="input-group">
                            <b-form-input :formatter="number"
                            v-model="row.aranla_cm_nacl" type="text" name="name"
                            class="form-control" placeholder="NaCl" />
                            <div class="input-group-append"><span class="input-group-text">cc</span></div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="">Koloid</label>
                          <div class="input-group">
                            <b-form-input :formatter="number"
                            v-model="row.aranla_cm_kolaid" type="text" name="name"
                            class="form-control" placeholder="Koloid" />
                            <div class="input-group-append"><span class="input-group-text">cc</span></div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="">Darah</label>
                          <div class="input-group">
                            <b-form-input :formatter="number"
                            v-model="row.aranla_cm_darah" type="text" name="name"
                            class="form-control" placeholder="Darah" />
                            <div class="input-group-append"><span class="input-group-text">cc</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <h6 class="font-weight-semibold">Cairan Keluar</h6>
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="">Urin</label>
                          <div class="input-group">
                            <b-form-input :formatter="number"
                            v-model="row.aranla_ck_urin" type="text" name="name"
                            class="form-control" placeholder="Urin" />
                            <div class="input-group-append"><span class="input-group-text">cc</span></div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="">Pendarahan</label>
                          <div class="input-group">
                            <b-form-input :formatter="number"
                            v-model="row.aranla_ck_pendarahan" type="text" name="name"
                            class="form-control" placeholder="Pendarahan" />
                            <div class="input-group-append"><span class="input-group-text">cc</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  -->
                </div>
              </div>
            </div>
            <!--
            <h6 class="font-weight-semibold">Informasi Bayi</h6>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="">Bayi Lahir Jam</label>
                  <div class="input-group">
                    <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                    <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.aranla_bayi_lahir">
                    </vue-timepicker>
                    <div class="input-group-append"><span class="input-group-text">WIB</span></div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="">Jenis Kelamin</label>
                  <div>
                    <v-select placeholder="Pilih Jenis Kelamin" v-model="row.aranla_jenis_kelamin"
                    :options="Config.mr.genderText" label="text"
                    :clearable="true" :reduce="v=>v.value"></v-select>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Berat Badan</label>
                  <div class="input-group">
                    <b-form-input :formatter="number"
                    v-model="row.aranla_bb" type="text" name="name"
                    class="form-control" placeholder="Berat Badan" />
                    <div class="input-group-append"><span class="input-group-text">gram</span></div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="">APGAR Score</label>
                  <b-form-input :formatter="number"
                    v-model="row.aranla_apgar" type="text" name="name"
                    class="form-control" placeholder="APGAR Score" />
                </div>
              </div>
            </div>
            -->
            <div class="wrap_line">
              <h3>Catatan Untuk di Ruangan (Berlaku 24 Jam Pasca Operasi)</h3>
              <div class="row">
                <div class="col-md-6 col-lg-4">
                  <div class="form-group">
                    <label for="">Pengawasan</label>
                    <div class="input-group">
                      <b-form-input :formatter="number"
                      v-model="row.aranla_pengawasan" type="text" name="name"
                      class="form-control" placeholder="Pengawasan" />
                      <div class="input-group-append">
                        <span class="input-group-text">jam</span>
                      </div>
                    </div>
                    <small class="form-text text-secondary">Durasi pengawasan TD/Nadi/RR/Suhu tiap 1 atau 2 jam</small>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="form-group">
                    <label for="">Bila terjadi kegawatan menghubungi</label>
                    <b-form-input v-model="row.aranla_kegawatan" type="text" name="name" class="form-control" />
                  </div>
                </div>
                <div class="w-100"></div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="progCairan">Program Cairan</label>
                    <b-form-textarea v-model="row.aranla_program_cairan" name="progCairan" id="progCairan" rows="4" class="form-control"></b-form-textarea>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="progAnalgetik">Program Analgetik</label>
                    <b-form-textarea v-model="row.aranla_program_analgetik" name="progAnalgetik" id="progAnalgetik" rows="4" class="form-control"></b-form-textarea>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="form-group">
                    <label for="">Jika terjadi mual diberi</label>
                    <b-form-textarea v-model="row.aranla_jika_mual" name="progAnalgetik" id="progAnalgetik" rows="4" class="form-control"></b-form-textarea>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="form-group">
                    <label for="">Jika menggigil diberi</label>
                    <b-form-textarea v-model="row.aranla_jika_menggigil" name="progAnalgetik" id="progAnalgetik" rows="4" class="form-control"></b-form-textarea>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="mb-1">
                      <div class="form-row align-items-center">
                        <label for="" class="col-md-auto mb-0">Jika tensi kurang dari </label>
                        <div class="col-md-6">
                          <div class="input-group input-group-sm">
                            <b-form-input :formatter="number"
                            v-model="row.aranla_tekanan_darah_min_pengawasan" type="text" name="name"
                            class="form-control" placeholder="Systole" />
                            <div class="input-group-append input-group-prepend">
                            <span class="input-group-text">/</span>
                            </div>
                            <b-form-input :formatter="number" placeholder="Diastole"
                            v-model="row.aranla_tekanan_darah_max_pengawasan" type="text"
                            class="form-control" />
                            <div class="input-group-append"><span class="input-group-text">mmHG</span>
                            </div>
                          </div>
                        </div>
                        <label for="" class="col-md-auto mb-0">, lakukan:</label>
                      </div>
                    </div>
                    <b-form-textarea v-model="row.aranla_tensi_action" cols="30" rows="3" class="form-control"></b-form-textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="">Tanggal <strong class="text-danger">*</strong></label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="icon-calendar22"></i></span>
                      </div>
                      <datepicker :clear-button="true" input-class="form-control transparent" 
                          calendar-class="my-datepicker"
                          v-model="row.aranla_tanggal_pengawasan">
                      </datepicker>
                    </div>
                    <VValidate 
                      name="Tanggal Pengawasan" 
                      v-model="row.aranla_tanggal_pengawasan" 
                      :rules="toValidate(mrValidation.aranla_tanggal_pengawasan)"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div>
                    <label for="">Jam <strong class="text-danger">*</strong></label>
                    
                    <div class="input-group">
                      <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                      <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.aranla_jam_pengawasan">
                      </vue-timepicker>
                    </div>
                    <VValidate 
                      name="Jam Pengawasan" 
                      v-model="row.aranla_jam_pengawasan" 
                      :rules="toValidate(mrValidation.aranla_jam_pengawasan)"
                    />

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
              <div class="text-right">
                <button type="button" @click="back()" class="btn btn-light mr-1">Back</button>
                <button type="button" @click="downloadLaporan()" class="btn btn-primary mr-1">Unduh Dokumen</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                <br/>
                <small class="mt-2" v-if="row.aranla_updated_date">Terakhir Diupdate : {{row.aranla_updated_date | moment("DD MMMM YYYY, HH:mm") }}</small>
              </div>
            </div>
          </div>
        </b-form>
      </validation-observer>

      <b-modal v-model="openFisiologis" :title="'Isi Pantauan Fisiologis'" size="xl" ok-only>
        <div class="row">
          <div class="col-md-7">
            <div class="form-row">
              <div class="col-md" style="min-height: 650px;" ref="contentToConvert">
                <div class="fsg-main">
                  <img :src="assetLocal('img/pantauan-fisiologis-bg.png')" style="max-width: 100%;position:absolute;pointer-events: none;">
                </div>
                <div class="fsg-main">
                <VueSignaturePad
                    class="sign-data"
                    ref="signaturePad"
                    :options="options"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5" id="sidebar">
            <div class="colorButtons form-group">
              <label>Warna</label>
              <div class="row">
                <div class="col-md-6">
                  <v-select placeholder="Pilih Warna" v-model="hexColor"
                  :options="hexColorConfig" label="text"
                  :clearable="true" :reduce="v=>v.value"></v-select>
                </div>
                <div class="col-md-6">
                  <a href="javascript:;" @click="changeColor" class="btn btn-info mr-1">Ganti Warna</a>
                </div>
              </div>
            </div>

            <div class="extra form-group">
              <label>Aksi</label>
              <div>
                <a href="javascript:;" @click="undoSign('signaturePad','aranla_pemantauan_fisiologis')" class="btn btn-warning mr-1">Undo</a>
                <a href="javascript:;" @click="undoTTD('signaturePad','aranla_pemantauan_fisiologis')" class="btn btn-danger mr-1">Clear</a>
                <a href="javascript:;" @click="outputSign('signaturePad','aranla_pemantauan_fisiologis')" class="btn btn-success mr-1">Save</a>
                <a href="javascript:;" @click="saveGraph('main-contain','aranla_pemantauan_fisiologis_with_image')" class="btn btn-info mr-1">Unduh</a>
              </div>
            </div>
          </div>
        </div>
      </b-modal>

      <!--
      <div class="container">
        <VueSignaturePad
          id="signature"
          width="100%"
          height="500px"
          ref="signaturePad"
          :options="options"
        />
      </div>
      <div class="buttons">
        <button @click="undo">Undo</button>
        <button @click="save">Save</button>
        <button @click="change">Change Color</button>
        <button @click="resume">Resume Color</button>
      </div>
      -->

    </div>
  </div>
</template>



<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
const moment = require('moment')
import { stringSimilarity } from "string-similarity-js"
import html2canvas from 'html2canvas'
import PatientInfo from '@/components/Ranap/PatientInfo.vue'
import ICD10Operasi from '@/components/Ranap/ICD10Operasi.vue'

export default {
  extends: GlobalVue,
  data(){
    return {
      rowReg: {},
      menuPage: 'RanapLaporanAnestesi',
      
      isEraserEnabled: false,

      mKananKiri: [
        {
          value: "Kanan",
          text: "Kanan",
        },
        {
          value: "Kiri",
          text: "Kiri",
        }
      ],

      mObatPreferensi: [],
      obatLen: 0,
      totalRows: 0,
      
      options: {
        penColor: "rgba(0,0,0,0)",
        backgroundColor: 'rgba(0,0,0,0)',
      },
      hexColor: "",
      openFisiologis: false,
      
      patientData: {},
      loading: {
          patientInfo: false,
      },

      mAsa: [
        {
          text: 1,
          value: 1,
        },
        {
          text: 2,
          value: 2,
        },
        {
          text: 3,
          value: 3,
        },
        {
          text: 4,
          value: 4,
        },
      ],
      hexColorConfig: [
        {
          text : "Tensi",
          value : "#FF0000"
        },
        {
          text : "RR",
          value : "#18101C"
        },
        {
          text : "Nadi",
          value : "#0061E1"
        },
        {
          text : "Lainnya",
          value : "#235225"
        },

      ],
      
      isReqLaporanAnestesiPostOperasi: false,
      isReqLaporanAnestesiPraOperasi: false,
    }
  },
  

  computed:{
    distanceAnestesi(){
      let startDate = moment(moment(this.row.aranla_anastesi_date_start).format("YYYY-MM-DD") + ' ' + this.row.aranla_anastesi_jam_start)
      let endDate = moment(moment(this.row.aranla_anastesi_date_end).format("YYYY-MM-DD") + ' ' + this.row.aranla_anastesi_jam_end)
      let durationInMinutes = endDate.diff(startDate, 'minutes')

      if (isNaN(durationInMinutes)) {
          return '0 Menit'
      } else {
          let hours = Math.floor(durationInMinutes / 60)
          let minutes = durationInMinutes % 60
          return hours + ' Jam ' + minutes + ' Menit'
      }

    },
    distanceOperasi(){
      let startDate = moment(moment(this.row.aranla_operasi_date_start).format("YYYY-MM-DD") + ' ' + this.row.aranla_operasi_jam_start)
      let endDate = moment(moment(this.row.aranla_operasi_date_end).format("YYYY-MM-DD") + ' ' + this.row.aranla_operasi_jam_end)
      let durationInMinutes = endDate.diff(startDate, 'minutes')

      if (isNaN(durationInMinutes)) {
          return '0 Menit'
      } else {
          let hours = Math.floor(durationInMinutes / 60)
          let minutes = durationInMinutes % 60
          return hours + ' Jam ' + minutes + ' Menit'
      }

    }
  },
  components:{ Datepicker,VueTimepicker,PatientInfo,ICD10Operasi },
  mounted() {
    this.apiGet() 
    
    this.getPatientInfo()
  },
  methods: {

    autofill(e,to){
      this.row[to] = e
      console.log(e,to)
    },
    //contentToConvert
    saveGraph(id,field){
      let self = this
      let filename = 'laporan-anestesi.png'
      self.loadingOverlay = true
      html2canvas(this.$refs['contentToConvert']).then(canvas => {  
        self.loadingOverlay = false
        var link = document.createElement('a')
        link.href = canvas.toDataURL()
        link.download = `laporan-anestesi_${moment().format("YYYY-MM-DD")}.png`
        link.click()

      }).catch(error => {
        alert(error)
      })
    },
    doOpenFisiologis(){
      this.openFisiologis = true
      setTimeout(()=>{
        $(".sign-data canvas").attr("width",632)
        $(".sign-data canvas").attr("height",600)  
        this.$nextTick(() => {
          if(this.row.aranla_pemantauan_fisiologis){
            if(this.$refs['signaturePad']){
              this.$refs['signaturePad'].fromDataURL(this.row.aranla_pemantauan_fisiologis)
            }
          }
        })
      })
    },
    changeColor() {
      this.options = {
        penColor: this.hexColor,
      }
      return this.$swal({
        icon: 'success',
        title: 'Berhasil Mengganti Warna'
      })
    },
    undoTTD(refs,field){
      this.$refs[refs].clearSignature()
      this.row[field] = null
      setTimeout(()=>{
        this.autoSave(this.row,true)
      },100)
    },
    undoSign(refs,field){
      this.$refs[refs].undoSignature()
      this.output(refs, field)
    },
    outputSign(refs,field){
      let { isEmpty, data } = this.$refs[refs].saveSignature()
      if(!isEmpty){
        this.row[field] = data
        setTimeout(()=>{
          this.autoSave(this.row,true)
        },100)
      }
      let self = this      
      html2canvas(self.$refs['contentToConvert']).then(canvas => {  
        let data = canvas.toDataURL()
        self.row['aranla_pemantauan_fisiologis_last_update'] = moment().format("YYYY-MM-DD HH:mm:ss")
        self.row[field+'_with_image'] = data
        self.autoSave(self.row,true)
      })

    },
    output(refs,field) {
      let { isEmpty, data } = this.$refs[refs].saveSignature()
      if(!isEmpty){
        this.row[field] = data
        setTimeout(()=>{
          this.autoSave(this.row,true)
        },100)
      }
    },

    back() {
      this.$router.back()
    },

    toValidate(val){
      return {...val}
    },

    apiGet(params = {}, page = 1){
        if(!this.pageSlug || !this.$route.query.regId){
            this.$router.push({name : 'Dashboard'}).catch(()=>{})
        }

        if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
        this.data.data = false
        let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
        let url = this.menuPage+'/'+this.$route.params.pageSlug
        
        Gen.apiRest(
            "/get/"+url, 
            {
            params: Object.assign({page: page}, paramsQuery, params.query||{})
            }
        ).then(res=>{
            this.loadingOverlay = false
            _.forEach(res.data, (v,k)=>{
                this.$set(this, k, v)
            })
                  
            if(this.$refs['ttdDokterAnestesi']){
              this.$refs['ttdDokterAnestesi'].fromDataURL(this.row.aranla_ttd_dokter_anestesi)
            } 
            if(this.$refs['ttdPerawatAnestesi']){
              this.$refs['ttdPerawatAnestesi'].fromDataURL(this.row.aranla_ttd_asisten_anestesi)
            } 

            if(!this.isFound){
                this.$router.push({name : 'Dashboard'}).catch(()=>{})
            }
            
            setTimeout(()=>{
              this.isReqLaporanAnestesiPostOperasi = $("#icd-10-op-"+'LaporanAnestesiPostOperasi').length ? false : true
              this.isReqLaporanAnestesiPraOperasi = $("#icd-10-op-"+'LaporanAnestesiPraOperasi').length ? false : true
            },6000)  
        }).catch(()=>{
            this.loadingOverlay = false
        })

        // master
        if(this.$route.query.regId){
            Gen.apiRest(
                "/get/"+url+'?master=1', 
                {
                params: Object.assign({page: page}, paramsQuery, params.query||{})
                }
            ).then(res=>{
                console.log(res.data)
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
            })
        }
        
    },
   
    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
      "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.query.regId
          }
      },
      "POST"
      ).then(resp => {
      this.$set(this, 'patientData', resp.data.data)

      this.$set(this.loading, 'patientInfo', false)
      })
    },

    getConfigDynamic(master,value){
        let text = ''
        if(value){
            let index = (master||[]).findIndex(x => x.value == value)
            if(index !== -1){
                text = master[index]['text']
            }
        }
        return text
    },
    
    autoSave: _.debounce(function (data) {
        data.type = 'auto-save'
        if(!data.isEdit){
            Gen.apiRest(
                "/do/"+this.menuPage,
                {data:data}, 
                "POST"
            )
        }
    },1000),
  
    doSubmit(){
      this.$refs['VForm'].validate().then(success => {
        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
              if (result.value) {
                  setTimeout(() => {
                      let inv = []
                      let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                      console.log(el)
                      for (let i = 0; i < (el || []).length; i++) {
                          if (el[i].style.display !== 'none') {
                          inv.push(el[i].id)
                          }
                      }
                                  
                      if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                          behavior: 'smooth',
                          block: 'center'
                      })
                  }, 500)
              }
          })
        }
        if (success) {
          this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menyimpan data ini?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = { ...this.row, idReg: this.rowReg.aranr_id }
              data.type = 'submit-data'
              
              this.loadingOverlay = true
              Gen.apiRest(
              "/do/" + this.menuPage, {
                  data: data
              },
              "POST"
              ).then(res => {
                this.loadingOverlay = false
                
                let resp = res.data
                resp.statusType = 200
                this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(result => {
                    this.$router.push({ name: 'RanapOperasiDokterAnestesi'}).catch(()=>{})   
                })
              }).catch(err => {
                this.loadingOverlay = false
                if (err) {
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response?.data?.title
                  err.message = err.response?.data?.message
                  err.messageError = err.message
                }
                this.doSetAlertForm(err)
              })
            }
          })
        }
      })
    },


    //obat

    selectJumlahSatuan(e, k, col) {
        let data = {
          id: e,
          type: 'get-by-obat'
        }
        Gen.apiRest(
        "/do/" + 'RanapCPPTDokter', {
          data: data
        },
        "POST"
        ).then(res => {
            this.row[col][k].nama = res.data.nama
            console.log(this.row[col][k].nama)
        })
    },
    ceilData(value) {
        return Math.ceil(value)
    },
    countLen() {
      setTimeout(() => {
          this.obatLen = $('.sp-col ul li').length
      }, 100)
    },
    isAlergiLabel(nama) {
        let data = []
        for (let i = 0; i < (this.row.ap_alergi || []).length; i++) {
            if (this.row.ap_alergi[i]['jenis'] == 'Obat') {
                data.push(this.row.ap_alergi[i]['name'])
            }
        }
        let isWarning = 0
        for (let i = 0; i < (data || []).length; i++) {
            let result = stringSimilarity(nama||"", data[i])
            if (result > 0.3) isWarning += 1
        }
        return isWarning
    },
    changePageObat(page, k, act, col) {
      if (act == 'min') {
          if (page > 1) {
              page -= 1
              this.row[col][k]['currentPage'] -= 1
          }
      } else {
          if (page < this.ceilData(this.row[col][k]['totalRows'] / 10)) {
              page += 1
              this.row[col][k]['currentPage'] += 1
          }
      }

      let data = {
          type: 'select-paging-obat',
          page: page,
          idDokter: this.rowReg.aranr_dokter_id,
          search: this.row[col][k]['search']
      }
      Gen.apiRest(
      "/do/" + 'RanapCPPTDokter', {
          data: data
      },
      "POST"
      ).then(res => {
          this.mObatPreferensi = res.data.data
      })
    },
    
    filterObat: _.debounce(function (e, k, col) {
        if (e) {
            this.row[col][k]['search'] = e
            let id = []
            for (let i = 0; i < (this.row[col] || []).length; i++) {
                if (this.row[col][i]['item_id']) {
                  id.push(this.row[col][i]['item_id'])
                }
            }
            Gen.apiRest('/do/' + 'RanapCPPTDokter', {
                data: {
                    type: 'select-obat',
                    search: e,
                    id: id
                }
            }, 'POST').then(res => {
                this.mObatPreferensi = res.data.data
                this.row[col][k]['currentPage'] = 1
                this.row[col][k]['totalRows'] = res.data.totalRows
            })
        }
    }, 10),

    openObat(col) {
        let data = {
          nama: null,
          item_id: null,
          ket: null,
          currentPage: 1,
          totalRows: this.totalRows
        }
        this.row[col].push(data)
    },
    
    removeObat(k,col) {
      this.row[col].splice(k, 1)
    },

    //obat

    
    downloadLaporan(){
      let data = {exptype: 'xlsx', type: "export", id: this.row.arano_id}
      let self = this
      $.ajax({
          type: "POST",
          url: process.env.VUE_APP_API_URL + `/report/${'laporanAnestesi'}?token=IXs1029102asoaksoas102901290`,
          data: data,
          cache: false,
          xhrFields:{
              responseType: 'blob'
          },
          success: data => 
          {
              self.loadingOverlay = false
              var link = document.createElement('a')
              link.href = window.URL.createObjectURL(data)
              link.download = `Laporan-Anestesi-${moment().format("YYYY-MM-DD")}.pdf`
              link.click()
          },
          fail: data => {
              self.loadingOverlay = false
              alert('Not downloaded')
          }
      })
    },
  },
  watch:{
    row: {
        handler(v) {
            this.isReqLaporanAnestesiPostOperasi = $("#icd-10-op-"+'LaporanAnestesiPostOperasi').length ? false : true
            this.isReqLaporanAnestesiPraOperasi = $("#icd-10-op-"+'LaporanAnestesiPraOperasi').length ? false : true
            this.autoSave(v)
          
            this.autoSave(v)
        },
        deep: true
    },
    tingkatKesadaranTotal(v){
        setTimeout(()=>{
            this.row.arankm_response_gcs = v
        },250)
    },
    distanceAnestesi(v) {
      setTimeout(()=>{
        this.row.aranla_lama_anestesi = v
      },250)
    },
    distanceOperasi(v){
      setTimeout(()=>{
        this.row.aranla_lama_operasi = v
      },250)
    }
  }
}
</script>